import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import orange from '@mui/material/colors/orange'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'
//import configureStore from './store/configureStore';
import 'mapbox-gl/dist/mapbox-gl.css'

const theme = createTheme({
  typography: {
    fontFamily: ['Jost', 'sans-serif', 'Noto Serif Display', 'serif'].join(','),
  },
  palette: {
    primary: {
      main: '#2A6280'
    },
    secondary: {
      main: '#6B92A5',
    },
    redorange: {
      main: '#DB6028',
    },
    tan: {
      main: '#D2A18D',
    },
    yellow: {
      main: '#D3A02A',
    },
    offwhite: {
      main: '#ECECEC',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  status: {
    primary: orange[500],
    danger: orange[500],
  },
})
theme.typography.h2 = {
  fontFamily:'Noto Serif Display',
  fontWeight: '500',
  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '48px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '68px',
  },
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
