import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Fab, TextField, ButtonGroup, Card, CircularProgress, Divider, CardContent, CardActions } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Products from '../components/luggage/Products'
import Categories from '../components/product/Categories'
import AdditionalFilters from '../components/product/AdditionalFilters'
import ProductCard from '../components/product/ProductCard'
import TripDetails from './TripDetails'
import { setMemberName, setMemberEmail } from '../redux/tripSlice'


/*
What Filters Will we Need:
price point (check boxes)
gender (check boxes)
size
brand

 */

// TODO: MAKE SURE THE SEND INVITE BUTTON ACTUALLY WORKS @UMAIR

function Members(props) {
  let history = useHistory()
  const dispatch = useDispatch()
  const members = useSelector((state) => state.trip.members)

  const updateMemberName = (e, index) => {
    dispatch(setMemberName({ name: e.target.value, index }))
  }

  const updateMemberEmail = (e, index) => {
    dispatch(setMemberEmail({ email: e.target.value, index }))
  }

  return (
    <Grid
      container
      justifyContent="space-around"
      style={{
        height: '100%',
        marginTop: 24,
      }}
    >
      <Grid container style={{ alignItems:'center', justifyContent:'center'}}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 32 }}> Trip Member Details </Typography>
      </Grid>
       <TripDetails />
        <Grid container style={{width:'60%', justifyContent:'center', alignItems:'center'}}>
          {members.map((member, index) => {
            return (
              <Grid item  sx={{p:1}}>
                <Card variant="outlined" style={{borderRadius: 0}}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {member.name}
                    </Typography>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      required
                      id="outlined-required"
                      label="Name"
                      onChange={(e) => updateMemberName(e, index)}
                    />
                    <Typography sx={{ mb: 0, mt:2 }} color="text.secondary">
                      Additional Details (optional)
                    </Typography>

                    <Grid container style={{ justifyContent: 'space-between' }}>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          variant="standard"
                          fullWidth
                          id="outlined-required"
                          label="Email"
                          onChange={(e) => updateMemberEmail(e, index)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Button style={{borderRadius: 0}} size="small" variant="outlined">
                          Send Invite
                        </Button>
                      </Grid>
                    </Grid>
                   </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>

      <Grid container style={{ alignItems:'center', justifyContent:'center'}} sx={{p:2}}>
        <Button style={{borderRadius: 0}} size="small" variant="contained" onClick={()=>history.push('/membership-plans')}>
          {
            //all-items
          }
          Continue &rarr;
        </Button>
      </Grid>
    </Grid>
  )
}

export default Members
