import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Fab, TextField, ButtonGroup, Card, CircularProgress, Divider, CardContent, CardActions } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Products from '../components/luggage/Products'
import Categories from '../components/product/Categories'
import AdditionalFilters from '../components/product/AdditionalFilters'
import ProductCard from '../components/product/ProductCard'
import { setMemberName, setMemberEmail } from '../redux/tripSlice'
import moment from 'moment'


/*
What Filters Will we Need:
price point (check boxes)
gender (check boxes)
size
brand

 */

// TODO: MAKE SURE THE SEND INVITE BUTTON ACTUALLY WORKS @UMAIR

function TripDetails(props) {
  let history = useHistory()
  const dispatch = useDispatch()
  const destination = useSelector((state) => state.trip.location)

  const checkIn = useSelector((state) => state.trip.checkIn)

  const checkOut = useSelector((state) => state.trip.checkOut)

  const updateMemberName = (e, index) => {
    dispatch(setMemberName({ name: e.target.value, index }))
  }

  const updateMemberEmail = (e, index) => {
    dispatch(setMemberEmail({ email: e.target.value, index }))
  }

  return (
    <>
      {destination && <><Grid container style={{ alignItems:'center', justifyContent:'center'}}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}> Destination Information </Typography>
      </Grid>
      <Grid container xs={12} style={{justifyContent:'center'}}>
        {destination}
      </Grid></>}
      <Grid container style={{ alignItems:'center', justifyContent:'center'}}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}> Check-in Check-out Information </Typography>
      </Grid>
      <Grid container xs={12} style={{justifyContent:'center'}}>
      {moment(checkIn).format("MMM Do YY")} &rarr; {moment(checkOut).format("MMM Do YY")}
      </Grid>
    </>
  )
}

export default TripDetails
