import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

/**
 * [uiSlice description]
 * @type {[type]}
 */
export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    checkoutOpen: false,
    additionalFilterOpen: false,
  },
  reducers: {
    openCheckout: (state) => {
      state.checkoutOpen = true
    },
    closeCheckout: (state) => {
      state.checkoutOpen = false
    },
    setAdditionalFilterOpen: (state, action) => {
      state.additionalFilterOpen = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { openCheckout, closeCheckout, setAdditionalFilterOpen } = uiSlice.actions

export default uiSlice.reducer
