import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import MapBox from './MapBox';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { EditOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux'
import { setLocation } from '../../redux/tripSlice'



export default function PopMap() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useSelector((state) => state.trip.location)
  const dispatch = useDispatch()

  const handleChangeLocation = (e) => {
    dispatch(setLocation(e))
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{width: '100%'}}>
      <Button aria-describedby={id} onClick={handleClick}>
        {!location && (
            <>
            <SearchIcon sx={{pr:1}}/>
            <Typography sx={{textTransform:'none'}}>
            Where are you staying?
            </Typography>
            </>
            
        )}
        {location && (
            <>
                <EditOutlined sx={{pr:1}}/>
                {location.length > 25 ? location.substring(0, 20).concat('...'): location}
            </>
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, width: [320,500,700], height: 500, pb:0, mb: 0 }}>
          <MapBox setDestinationData={handleChangeLocation}/>
        </Box>
      </Popover>
    </div>
  );
}