import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
// STRIPE
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// REDUX
import { useSelector, useDispatch } from 'react-redux'
// Main Components
import './App.css'
import AuthModal from './AuthModal'
import Home from './screens/Home'

import Members from './screens/Members'

import Account from './screens/Account'
import Terms from './screens/Terms'

import AllItems from './screens/AllItems'
import Header from './components/Header'
import TripBar from './components/TripBar'
import CheckoutDrawer from './components/checkout/CheckoutDrawer'
// REDUX PT2:
import { setUser, addTrip, addOrder, pushSavedMember, clearSavedMembers } from './redux/mainSlice'
// amplify
import { Amplify, API, graphqlOperation, Hub, Logger } from 'aws-amplify';

import Plane from './assets/plane.png'
import Pillow from './assets/pillow.png'
import awsconfig from './aws-exports';
import MembershipPlans from './screens/MembershipPlans'
//import { Hub } from 'aws-amplify';

Amplify.configure(awsconfig);

const stripePromise = loadStripe('pk_test_OlL5F9gfcwKRi60SAfn8U7hS00gi0cUpXR')
const logger = new Logger('My-Logger');

function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector((state) => state.main.user)
  const [authModalOpen, setAuthModalOpen] = useState(false)
  const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in:', data.payload);
            const username = data.payload.data.username
            console.log("username: ",data.payload.data.username);
            dispatch(setUser(username))
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            dispatch(setUser(null))
            console.log('user signed out');
            break;
        case 'signIn_failure':
            console.log('user sign in failed');
            break;
        case 'tokenRefresh':
            console.log('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            console.log('token refresh failed');
            break;
        case 'autoSignIn':
            console.log('Auto Sign In after Sign Up succeeded');
            break;
        case 'autoSignIn_failure':
            console.log('Auto Sign In after Sign Up failed');
            break;
        case 'configured':
            console.log('the Auth module is configured');
    }
}

Hub.listen('auth', listener);

  function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if (event === 'autoSignIn') {
            const user = payload.data;
            // assign user
        } else if (event === 'autoSignIn_failure') {
            // redirect to sign in page
        }
    })
}
  const backgroundImage = () =>{
    return location.pathname=='/trip-members' ? `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.4)), url(${Plane})`:location.pathname=='/membership-plans'? `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.4)), url(${Pillow})`: 'none'
  }
  return (
    <Elements stripe={stripePromise}>
      <Grid fullWidth style={{justifyContent: 'space-around', minHeight:'1080px', backgroundImage:backgroundImage(),backgroundRepeat: 'no-repeat', backgroundPosition: "center center",backgroundSize: "cover",
    backgroundAttachment: "fixed",}} >
      {(user === undefined || user === null) && (
        <AuthModal onClose={() => setAuthModalOpen(false)} isOpen={authModalOpen} />
      )}
      
      <Header />
      <CheckoutDrawer/>
      <Switch>
        <Grid
          style={{
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            height: '100%',
          }}
          container
        >
          <Route exact path="/">
            <TripBar />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/all-items">
            <AllItems />
          </Route>
          <Route path="/trip-members">
            <Members />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/membership-plans">
            <MembershipPlans />
          </Route>
          
        </Grid>
      </Switch>
      </Grid>
      
    </Elements>
  )
}

export default App
