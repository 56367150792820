import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, Button, Fab, TextField, ButtonGroup, Card, CircularProgress, Drawer, useMediaQuery, IconButton, Tooltip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import Products from '../components/luggage/Products'
import Categories from '../components/product/Categories'
import AdditionalFilters from '../components/product/AdditionalFilters'
import ProductCard from '../components/product/ProductCard'
import { API, graphqlOperation } from 'aws-amplify';
import { getProducts } from '../graphql/queries';
import { Auth } from 'aws-amplify';
import { useTheme } from '@mui/styles'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { setAdditionalFilterOpen } from '../redux/uiSlice'
import FilterListIcon from '@mui/icons-material/FilterList';
/*
What Filters Will we Need:
price point (check boxes)
gender (check boxes)
size
brand

 */

// TODO: MAKE THIS PAGE MOBILE RESPONSIVE PLEASE, FILTERS ETC COLLAPSE NICELY
// BUT THE PRODUCTS THEMSELVES SHOULD HAVE FEWER PER ROW POSSIBLY EVEN ONLY 1
// also add a scroll to top
// OVERLAP AT WIDTH 344 ON ADDITIONAL FILTERS SECTION

function AllItems() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch()
  const additionalFilterOpen = useSelector((state)=>state.ui.additionalFilterOpen)
  const theme = useTheme();
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async (cognito) => {
      console.log("FETCHING PRODUCTS...");
      setLoading(true);
      try {
        const prods = await API.graphql({
          query: getProducts,
          //variables: {url: userUrl},
          authMode: cognito ? null : "AWS_IAM"
        });
        /* const prods = await API.graphql({
          query: getProducts,
          //variables: {url: userUrl},
          authMode: cognito ? "AWS_COGNITO_USER_POOLS" : "AWS_IAM"
        }); */
        //setTaskerName(profile.data.getUserByUrl.name);
        //setTaskerEmail(profile.data.getUserByUrl.email);
        // set state with the result
        //setLoading(false);
        console.log("fetched products: ", prods.data.getProducts);
        setProducts(prods.data.getProducts)
        setLoading(false)
      } catch (e) {
        //setLoading(false);
        //setNullUrl(true);
        console.log("ERROR FETCHING PRODUCTS: ", e);
        setLoading(false)
        setErrorMessage(e.message)
      }
      
      /* try {
        
        const products = await API.graphql(graphqlOperation(getProducts));
        console.log("PRODUCTS: ", products);
      } catch (error) {
        console.log("ERROR FETCHING PRODUCTS: ", error);
      } */
      
    }

    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log("cau: ", user);
        if(user.username) {
          fetchData(true);
        }
      })
      .then(data => console.log("data", data))
      .catch(err => fetchData(false));

    // call the function
    //fetchData(false);

  }, []);

  return (
    <Grid
      container
      justify="center"
      style={{
        height: '100%',
        marginTop: 24,
      }}
    >
      <Grid container style={{ alignItems:'center', justifyContent:'center'}}>
        <Grid item xs={12} style={{}}>
          <Categories/>
        </Grid>
      </Grid>
      <Grid item sm={3} style={{}}>
        {useMediaQuery(theme.breakpoints.down('sm')) ? 
          <Drawer open={additionalFilterOpen} anchor={'left'}>
            <Grid container style={{width:'300px',paddingTop:35}} justifyContent="flex-end">
              <IconButton 
                onClick={()=>{dispatch(setAdditionalFilterOpen(false))}}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <AdditionalFilters/>
            </Grid>
          </Drawer> : 
          <AdditionalFilters/>}
      </Grid>
      <Grid item sm={9} style={{}}>
        {loading && <CircularProgress/>}
        {!loading && (
          <Grid container>
          {products.map((item, index) => {
            return (
              <Grid key={index} style={{ padding: 15 }} item xs={12} sm={6} md={4} lg={3}>
                <ProductCard product={item}/>
              </Grid>
            )
          })}
        </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default AllItems
