import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Paper,
  InputBase,
  TextField,
  Popover,
  Stack,
  ButtonGroup,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// NOTE: INCONSISTENT SEMICOLON USE
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// REDUX
import { setLocation, setCheckIn, setCheckOut, addMember, subtractMember } from '../redux/tripSlice'
import PopMap from './destination/PopMap';

// TODO: DESIGN NOT RESPONSIVE, SPECIFICALLY THE MAIN BAR CHECKOUT DATE SELECTOR
export default function TripBarSmall() {
  let history = useHistory()
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.main.cart)
  const [checkIn, setCheckInVal] = useState(Date.now())
  const [checkOut, setCheckOutVal] = useState(Date.now())
  const [adults, setAdults] = useState(0)
  const [children, setChildren] = useState(0)
  const [value, setValue] = useState([null, null])

  const handleChangeLocation = (e) => {
    dispatch(setLocation(e.target.value))
  }

  const handleChangeCheckIn = (newValue) => {
    setCheckInVal(newValue)
    dispatch(setCheckIn(newValue.toString()))
  }
  const handleChangeCheckOut = (newValue) => {
    setCheckOutVal(newValue)
    dispatch(setCheckOut(newValue.toString()))
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddAdults = () => {
    setAdults(adults + 1)
    dispatch(addMember({ name: '', adult: true, cart: [] }))
  }

  const handleAddChildren = () => {
    setChildren(children + 1)
    dispatch(addMember({ name: '', adult: false, cart: [] }))
  }

  const handleSubtractAdults = () => {
    if (adults > 0) {
      const total = adults + children - 1
      dispatch(subtractMember(total))
      setAdults(adults - 1)
    }
  }

  const handleSubtractChildren = () => {
    if (children > 0) {
      const total = adults + children - 1
      dispatch(subtractMember(total))
      setChildren(children - 1)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container style={{ width: '100%', textAlign: 'center', maxHeight: 300 }}>
        <Grid
          container
          style={{
            padding: 15,
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
            <Grid item xs={12} sx={{m:2, border:1 }}>
              <PopMap />
            </Grid>
            
            {/* TODO: THIS PIKER HAS SOME ODD CLICKING FUNCTIONALITY...TESTING ON CHROME */}
            
            <DatePicker
              disablePast
              label="Check-in"
              value={checkIn}
              onChange={handleChangeCheckIn}
              renderInput={(params) => <TextField style={{ margin: 5, width: '45%' }} size="small" {...params} />}
            />
            <DatePicker
              label="Check-out"
              value={checkOut}
              onChange={handleChangeCheckOut}
              renderInput={(params) => <TextField style={{ margin: 5, width:'45%' }} size="small" {...params} />}
            />
      </Grid>
    </Grid>
    </LocalizationProvider>
  );
}
