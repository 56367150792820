import React, { useState, useRef, useEffect } from 'react'

// Maps
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl
});

mapboxgl.accessToken = 'pk.eyJ1Ijoic3RoZXRpYyIsImEiOiJjazFqbWc3a2kwM2ZmM250MXI4YjhydWgzIn0.U3GqypZ0XY2QX1fy0sDKwA'

function MapBox({setDestinationData}) {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lng, setLng] = useState(-70.9)
  const [lat, setLat] = useState(42.35)
  const [zoom, setZoom] = useState(9)
  //const dispatch = useDispatch()
  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom,
    })
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    })
    geocoder.on('result', function(result) {
      //SET GEOCODING HERE
      setDestinationData(result.result.place_name)
   console.log("GEOCODER RESULT: ", result.result.place_name);
  })
    map.current.addControl(
      geocoder
    );
  }, [])

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4))
      setLat(map.current.getCenter().lat.toFixed(4))
      setZoom(map.current.getZoom().toFixed(2))
    })
  }, [])

  return (<div ref={mapContainer} className="map-container" />)
}

export default MapBox
