import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { addProductToMemberCart } from '../../redux/tripSlice';
import { useDispatch, useSelector } from 'react-redux';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductModal(props) {
  const { product, addToCart } = props
  const [open, setOpen] = React.useState(false);
  const [activeImage, setActiveImage] = React.useState(0);
  const members = useSelector((state) => state.trip.members)
  const dispatch = useDispatch()

  const pricePerCategory = new Map();

  pricePerCategory.set('Family', '3.00');
  pricePerCategory.set('Designer', '8.00');
  pricePerCategory.set('Trending', '7.00');
  pricePerCategory.set('Lifestyle', '6.00');
  pricePerCategory.set('Personal', '5.00');
  pricePerCategory.set('Together', '4.00');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    console.log("Clicked Product: ", product);
    if (members.length === 0) {
      dispatch(
        addProductToMemberCart({
          product: { name: product.name, id: product.id, image: product.urls[0], price: pricePerCategory.get(product.category), description: product.description, size: 'L', ...product },
        })
      )
    } else {
      setAnchorEl(event.currentTarget)
    }
    setOpen(false)
    //setAlertOpen(true);
  }

  return (
    <React.Fragment>
      <Button size="small" variant="outlined" style={{ borderRadius: 0 }} onClick={handleClickOpen}>
        Learn More
      </Button>
      <Dialog
      fullWidth
        maxWidth='sm'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{product ? product.name: 'empty'}</DialogTitle>
        <DialogContent>
            <Grid container style={{alignItems:'center', textAlign:'center', justifyContent:'center'}}>
                <Grid item xs={12}>
                    <img src={product.urls[activeImage]} style={{ width: '60%', objectFit: 'cover' }} alt="product" />
                </Grid>
                <Grid item>
                {product.urls.map((image, index) => (
                    <IconButton onClick={ () => setActiveImage(index)}>
                        <CircleIcon color={activeImage === index ? 'primary': 'inherit'}/> 
                    </IconButton>
                ))}
                </Grid>
            </Grid>
          <DialogContentText id="alert-dialog-slide-description">
           {product ? product.description: 'empty'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
            onClick={handleClick}
            size="small"
            variant="contained"
            color="secondary"
            style={{ borderRadius: 0 }}
            >
                <AddShoppingCartIcon/>
            Add to Cart
            </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}