import * as React from 'react'
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
  IconButton,
} from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'
import { useSelector, useDispatch } from 'react-redux'
// REDUX
import { closeCheckout } from '../../redux/uiSlice'
import { removeProductFromCart } from '../../redux/tripSlice'

export default function CheckoutItemCard(props) {
  const dispatch = useDispatch()
  const { product, index } = props
  const { name, id, image, price, size, category } = product
  const handleRemove = () => {
    dispatch(removeProductFromCart(id));
  }
  return (
    <ListItem key={name}>
      
        <Grid container style={{justifyContent:'space-around', alignItems:'center'}}>
          <Grid item xs={3} sx={{borderRadius: 1, border: 1}} style={{ padding:5, margin:0, alignItems: 'center', justifyContent: 'center'}}>
            <img src={image} style={{ height: '100%',width: '100%', objectFit: 'cover' }} alt="product" />
          </Grid>
          <Grid item xs={6} style={{ flexGrow: 1 }} sx={{p:2}}>
            <Grid container>
              <Typography fullWidth sx={{fontSize: 24, fontWeight:'bold'}}> {name} </Typography>
            </Grid>
            <Grid container>
              <Typography> {category} </Typography>
            </Grid>
            <Grid container>
              <Typography> $:{price} </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ flexGrow: 1, justifyContent:'center', }} sx={{p:1}}>
            <IconButton onClick={handleRemove} size="large">
              <CancelIcon color='error' fontSize="large"/>
            </IconButton>
          </Grid>
        </Grid>
    </ListItem>
  )
}
