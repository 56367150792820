import * as React from 'react'
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
} from '@mui/material'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { useSelector, useDispatch } from 'react-redux'
// REDUX
import { closeCheckout } from '../../redux/uiSlice'
// Components
import CheckoutItemCard from './CheckoutItemCard'
import axios from 'axios'
import TripBarSmall from '../TripBarSmall'
import moment from 'moment'
import { Key } from '@mui/icons-material'



export default function CheckoutDrawer() {
  const dispatch = useDispatch()
  const checkoutOpen = useSelector((state) => state.ui.checkoutOpen)
  const { cart, members, checkOut, checkIn, location } = useSelector((state) => state.trip)
  const handleCloseCheckout = () => {
    dispatch(closeCheckout())
  }

  const categoryToPrice = new Map();

  categoryToPrice.set('Family', 'price_1LcN2mDwHVW4N2Br6R2GWpfG');
  categoryToPrice.set('Designer', 'price_1Lh2zJDwHVW4N2Br2N3w8kEp');
  categoryToPrice.set('Trending', 'price_1Lh2yrDwHVW4N2Brb6v8qBYL');
  categoryToPrice.set('Lifestyle', 'price_1Lh2yVDwHVW4N2BrXYmUAu77');
  categoryToPrice.set('Personal', 'price_1Lh2y0DwHVW4N2Br7Uuca9vQ');
  categoryToPrice.set('Together', 'price_1Lh2xgDwHVW4N2Brd55Narht');

  const priceIdCount = new Map();

  priceIdCount.set( 'price_1LcN2mDwHVW4N2Br6R2GWpfG', 0);
  priceIdCount.set('price_1Lh2zJDwHVW4N2Br2N3w8kEp', 0);
  priceIdCount.set('price_1Lh2yrDwHVW4N2Brb6v8qBYL', 0);
  priceIdCount.set( 'price_1Lh2yVDwHVW4N2BrXYmUAu77', 0);
  priceIdCount.set('price_1Lh2y0DwHVW4N2Br7Uuca9vQ', 0);
  priceIdCount.set('price_1Lh2xgDwHVW4N2Brd55Narht', 0);


  const testStripe = async () => {
    console.log("Testing Get Stripe Checkout");
    //axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    const priceCategoryArray = cart.map(item => item.category);
    console.log("priceCategoryArray: ", priceCategoryArray);
    const lineItemsUnformatted = priceCategoryArray.map(cat => priceIdCount.set(categoryToPrice.get(cat), priceIdCount.get(categoryToPrice.get(cat))+1))
    console.log("lineItemsUnformatted: ", lineItemsUnformatted);
    //const lineItemsUnformatted = priceCategoryArray.map(cat => priceIdCount.set(categoryToPrice.get(cat), priceIdCount.get(categoryToPrice.get(cat))+1))
    console.log("priceIdCount: ", priceIdCount);

    const lineItemsToAdd = new Map(
      [...priceIdCount]
      .filter(([k, v]) => v > 0 )
    );
    console.log("Line Items To Add: ", lineItemsToAdd);
    var lineItemsFinal = []
    lineItemsToAdd.forEach((v,k)=>lineItemsFinal.push({price: k, quantity:v }))
    console.log("lineItemsFinal: ", lineItemsFinal);
    const url = await axios({
      method: 'post',
      url: 'https://6wgozgasrjmampo5tqku6jxcfa0snfhk.lambda-url.us-east-1.on.aws/',
      data: {
        lineItems: lineItemsFinal,
        lastName: 'Flintstone'
      }
    });
    const checkoutSessionId = url.data.replace("https://checkout.stripe.com/c/pay/", "")
    console.log("checkout id: ", checkoutSessionId);
    
    const productIds = cart.map(item => item.id);
    console.log("productIds: ", productIds);
    window.open(url.data , "_blank")
  }

  const readyForCheckout = ( cart != null &&  members != null && checkOut != null && checkIn != null && location != null )
  const totalCostPerDay = cart.reduce((partialSum, item) => partialSum + parseFloat(item.price), 0.00);
  const totalDays = (moment(checkOut).diff(moment(checkIn), 'days'));
  const tax = 1.32;
  return (
    <Drawer anchor="right" open={checkoutOpen} onClose={handleCloseCheckout}>
      <Box sx={{ width: 450 }} role="presentation">
        <Grid container style={{}} sx={{pl:2, pt:2}}>
          <Typography sx={{fontSize:28, fontWeight: 'bold'}}> Cart </Typography>
        </Grid>
        <List>
          {cart.map((product, index) => (
            <>
            <CheckoutItemCard product={product} index={index} />
            <Divider />
            </>
          ))}
        </List>
        <Grid container style={{justifyContent:'center', alignItems:'center', textAlign:'center'}} sx={{p:2}}>
          { !readyForCheckout && (
            <Typography sx={{mb:2}} color="text.secondary"> We need a bit more information about your trip </Typography>
          )}
          <Divider />
          { !readyForCheckout && (
            <Grid item xs={12}>
              <Typography sx={{fontSize: 20, fontWeight: 'bold'}}> Trip Details <Divider sx={{mt:1}}/></Typography>
            </Grid>
          )}
          { !readyForCheckout && (
            <TripBarSmall/>
          )}
          
          { readyForCheckout && (
            <Grid item xs={12}>
            <Grid container style={{justifyContent:'space-between', textAlign:'left'}}>
              <Grid item>
                <Typography sx={{fontWeight: 700, fontSize: 16}}> Review Your Trip </Typography>
              </Grid>
              <Grid item xs={12} sx={{p:1, mt:1}}>
                <Typography> {location} </Typography>
              </Grid>
              <Grid item xs={12} sx={{p:1, mb:2}}>
                <Typography>{moment(checkIn).format("MMM Do YY")} &rarr; {moment(checkOut).format("MMM Do YY")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          )}

          <Box sx={{width:'100%', height:2, backgroundColor:'lightgrey', mb:2}} />
          { readyForCheckout && (
             <Grid item xs={12}>
             <Grid container style={{justifyContent:'space-between'}}>
               <Grid item>
                 <Typography> Total Days</Typography>
               </Grid>
               <Grid item>
                 <Typography> {totalDays.toString()} </Typography>
               </Grid>
             </Grid>
           </Grid>
          )}       
          { readyForCheckout && (
            <Grid item xs={12}>
            <Grid container style={{justifyContent:'space-between'}}>
              <Grid item>
                <Typography> Subtotal </Typography>
              </Grid>
              <Grid item>
                <Typography> ${totalCostPerDay.toFixed(2)} </Typography>
              </Grid>
            </Grid>
          </Grid>
          )}
           { readyForCheckout && (
              <Grid item xs={12}>
              <Grid container style={{justifyContent:'space-between'}}>
                <Grid item>
                  <Typography> Tax </Typography>
                </Grid>
                <Grid item>
                  <Typography> ${tax} </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          { readyForCheckout && (
            <Grid item xs={12}>
            <Grid container style={{justifyContent:'space-between'}}>
              <Grid item>
                <Typography> Total </Typography>
              </Grid>
              <Grid item>
                <Typography> ${(totalCostPerDay+tax).toFixed(2)} </Typography>
              </Grid>
            </Grid>
          </Grid>
          )}
          
          <Grid item sx={{pt:2}}>
            <Button disabled={!readyForCheckout} size="large" style={{borderRadius:0}} variant="contained" color="primary" onClick={testStripe}>
              Checkout
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  )
}
