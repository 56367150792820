import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './mainSlice'
import tripReducer from './tripSlice'
import uiReducer from './uiSlice'

export const store = configureStore({
  reducer: {
    main: mainReducer,
    trip: tripReducer,
    ui: uiReducer
  },
})
