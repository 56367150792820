import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ProductModal from './ProductModal'

// TODO: UPDATE PRODUCT CARD ADD TO CART TO REDUX TRIP GUEST LIST
import { addProductToMemberCart } from '../../redux/tripSlice'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProductCard(props) {
  const { product } = props
  const dispatch = useDispatch()
  const members = useSelector((state) => state.trip.members)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedMember, setSelectedMember] = React.useState(0)
  const [alertOpen, setAlertOpen] = React.useState(false)

  const pricePerCategory = new Map();

  pricePerCategory.set('Family', '3.00');
  pricePerCategory.set('Designer', '8.00');
  pricePerCategory.set('Trending', '7.00');
  pricePerCategory.set('Lifestyle', '6.00');
  pricePerCategory.set('Personal', '5.00');
  pricePerCategory.set('Together', '4.00');


  const renderAlert = () => {
    return <Snackbar open={alertOpen} autoHideDuration={1000} onClose={handleCloseAlert} anchorOrigin={{vertical: 'bottom',
    horizontal: 'right'}}>
    <Alert onClose={handleCloseAlert} color='yellow' sx={{ width: '100%' }}>
      Item Added to Cart!
    </Alert>
  </Snackbar>

  }

  const handleCloseAlert = () => {
    setAlertOpen(false)
  }

  const handleClick = (event) => {
    console.log("Clicked Product: ", product);
    if (members.length === 0) {
      dispatch(
        addProductToMemberCart({
          product: { name: product.name, id: product.id, image: product.urls[0], price: pricePerCategory.get(product.category), description: product.description, size: 'L', ...product },
        })
      )
    } else {
      setAnchorEl(event.currentTarget)
    }
    setAlertOpen(true);
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectMember = (e) => {
    setSelectedMember(e.target.value)
  }

  const handleAddToMemberCart = () => {
    dispatch(
      addProductToMemberCart({
        index: selectedMember,
        product: { name: product.name, id: product.id, image: product.urls[0], price: pricePerCategory.get(product.category), description: product.description, size: 'L', ...product },
      })
    )
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <Card sx={{ maxWidth: 345 }} style={{ borderRadius: 0, cursor:'pointer' }}>
      {renderAlert()}
      <img src={product.urls[0]} style={{ height: 150, width: '100%', objectFit: 'cover' }} alt="product" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
      </CardContent>
      <CardActions style={{justifyContent:'space-around'}}>
        <Button
          aria-describedby={id}
          onClick={handleClick}
          size="small"
          variant="contained"
          color="secondary"
          style={{ borderRadius: 0 }}
        >
          Add to Cart
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <FormControl sx={{ p: 2, m: 2 }}>
            <FormLabel id="demo-radio-buttons-group-label">Add To</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {members.map((member, index) => (
                <FormControlLabel value={index} control={<Radio onClick={handleSelectMember}/>} label={member.name} />
              ))}
            </RadioGroup>
            <Button size="small" onClick={handleAddToMemberCart} variant="outlined" style={{ borderRadius: 0 }}>
              &rarr;
            </Button>
          </FormControl>
        </Popover>
        <ProductModal product={product} addToCart={handleClick}/>
      </CardActions>
    </Card>
  )
}
