import { Grid, Typography, Button } from '@mui/material'
import { Auth } from 'aws-amplify';

import { useHistory } from 'react-router-dom'

function Account() {
  let history = useHistory()
  const auth = getAuth()
  const signOutUser = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
/*     signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      }) */
  }
  return (
    <Grid container justify="center" style={{ height: '100%', justifyContent:'center' }}>
      
    </Grid>
  )
}

export default Account
