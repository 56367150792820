import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Button,
  Fab,
  TextField,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  CardContent,
  CardActions,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Products from '../components/luggage/Products'
import Categories from '../components/product/Categories'
import AdditionalFilters from '../components/product/AdditionalFilters'
import ProductCard from '../components/product/ProductCard'
import { setMemberName, setMemberEmail } from '../redux/tripSlice'
import moment from 'moment'

/*
What Filters Will we Need:
price point (check boxes)
gender (check boxes)
size
brand

 */

// TODO: MAKE SURE THE SEND INVITE BUTTON ACTUALLY WORKS @UMAIR

function MembershipPlans(props) {
  let history = useHistory()
  const dispatch = useDispatch()
  const destination = useSelector((state) => state.trip.location)

  const checkIn = useSelector((state) => state.trip.checkIn)

  const checkOut = useSelector((state) => state.trip.checkOut)

  const updateMemberName = (e, index) => {
    dispatch(setMemberName({ name: e.target.value, index }))
  }

  const updateMemberEmail = (e, index) => {
    dispatch(setMemberEmail({ email: e.target.value, index }))
  }

  return (
    <>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'center',  }}
      >
        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
            {' '}
            Membership Plans{' '}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        <Grid item style={{ width: '60%' }}>
          When you become a
          <Typography
            sx={{ fontWeight: 'bold', fontSize: 24, display: 'inline' }}
          >
            TripWardrobe™
          </Typography>
          member you pay a one-time annual fee and get behind-the- scenes access
          to name brand accouterments available to you anytime you’re in a hurry
          and need to get to your destination with as little fanfare as
          possible.
        </Grid>
      </Grid>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
          AccouterBox® by TripWardrobe™ - $59.99/annually
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        <Typography style={{ justifyContent: 'center', width: '60%' }}>
          Member access to select store brands for tops and bottoms based on
          virtual luggage and wardrobe packages. Family, Together, and Personal
          Plans are included in this membership.{' '}
        </Typography>
      </Grid>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
          AccouterFit® by TripWardrobe™ - $99.99/annually
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ justifyContent: 'center', width: '60%'}}>
        <Typography style={{ justifyContent: 'center', width: '60%'}}>
          Unlimited member access to all available store brands for tops and
          bottoms including additional virtual packages for Lifestyle, Trending,
          and Designer Plans. Exclusive priority access for members with fitting
          room appointments at your local store/brand to ensure accurate sizes
          and measurements.
        </Typography>
      </Grid>
      <Grid
        container
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: 24 }}>
          AccouterSet® by TripWardrobe™ - $29.99/annually
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ justifyContent: 'center', width: '60%' }}>
        <Typography  style={{ justifyContent: 'center', width: '60%' }}>
          Purchase only access to undergarments, belts, socks, shoes, and
          hygiene items like deodorant, anti-perspirant, toothbrushes,
          toothpaste, lotions, hair supplies, and other trip necessities.
        </Typography>
      </Grid>

      <Grid container style={{ alignItems:'center', justifyContent:'center'}} sx={{p:2}}>
        <Button style={{borderRadius: 0}} size="small" variant="contained" onClick={()=>history.push('/all-items')}>
          {
            //all-items
          }
          Continue &rarr;
        </Button>
      </Grid>
    </>
  )
}

export default MembershipPlans
