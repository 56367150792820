import React, { useState } from 'react'
import { Grid, Chip } from '@mui/material'
import fashion from './CategoryFilters/fashion'

// Chip Objects Have A Title, Icon, and Associated Array of SubCategories
/*
  {
    title: "category1",
    icon: "icon1",
    subcategories:
      [
        {
          title: "sub1",
          icon: "iconsub1",
          subcategories:
            [
              ...
            ]
        },
        {
          title: "sub2",
          icon: "iconsub2",
          subcategories:
            [
              ...
            ]
        },
      ]
  }
*/

function Categories(props) {
  const [selection, setSelection] = useState([])
  const [chips, setChips] = useState(fashion)

  const addSelected = (chip) => {
    console.log('SELECTION: ', selection)
    console.log('CHIP: ', chip)
    let titles = selection.map((filter) => filter.title)
    titles.push(chip.title)
    console.log('TITLES: ', titles)
    //props.setFilters(titles)
    setSelection([...selection, chip])
  }
  const onDelete = (chip) => {
    let temp = selection.indexOf(chip)
    setSelection(selection.slice(0, temp))
//props.setFilters(selection.slice(0, temp))
    if (temp >= 1) {
      setChips(selection[temp - 1].subcategories)
    } else {
      setChips(fashion)
    }
  }

  return (
    <Grid alignItems="center" justify="center" container style={{width:'100%'}}>
      <Grid container style={{ alignItems:'center', justifyContent:'center'}}>
        {selection.map((selected, key) => {
          return (
            <Grid key={key} style={{ padding: 5 }} item>
              <Chip
                variant="outlined"
                style={{ padding: 5, borderRadius:0 }}
                label={selected.title}
                onClick={() => console.log('CLICK HERE')}
                onDelete={() => onDelete(selected)}
                color="primary"
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid style={{padding: 10, alignItems:'center', justifyContent:'center'}} container>
        {chips.map((chip, key) => {
          return (
            <Grid key={key} style={{ padding: 5 }} item>
              <Chip
                style={{ padding: 5, borderRadius:0 }}
                label={chip.title}
                onClick={() => {
                  setChips(chip.subcategories)
                  addSelected(chip)
                }}
                color="primary"
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

/*
<Chip
  size="small"
  icon={<FaceIcon />}
  label="Tops"
  onClick={()=>console.log("Clicked Tops")}
  color="secondary"
/>
<Chip
  size="small"
  icon={<FaceIcon />}
  label="Bottoms"
  onClick={()=>console.log("Clicked Tops")}
  color="secondary"
/>
<Chip
  size="small"
  icon={<FaceIcon />}
  label="Outerwear"
  onClick={()=>console.log("Clicked Tops")}
  color="secondary"
/>
<Chip
  size="small"
  icon={<FaceIcon />}
  label="Accessories"
  onClick={()=>console.log("Clicked Tops")}
  color="secondary"
/>
<Chip
  size="small"
  icon={<FaceIcon />}
  label="Formal"
  onClick={()=>console.log("Clicked Tops")}
  color="secondary"
/>
*/

export default Categories
