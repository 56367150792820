import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Button, Badge, Tooltip, useMediaQuery, Typography } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import AuthModal from '../AuthModal'
import logoStacked from '../logos/pngs/Logo_Stacked_Color.png'
import { openCheckout } from '../redux/uiSlice'
import Fab from '@mui/material/Fab';
import { ShoppingCartCheckoutOutlined } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList';
import { setAdditionalFilterOpen } from '../redux/uiSlice'


import AccountDropdown from'./AccountDropdown'
import { useTheme } from '@mui/styles'

export default function Header() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector((state) => state.main.user)
  const cart = useSelector((state) => state.trip.cart)
  const [authModalOpen, setAuthModalOpen] = useState(false)
  const theme = useTheme();
  const openCheckoutDrawer = () => {
    dispatch(openCheckout())
  }

  return (
    <Grid
      container
      style={{
        width: '100%',
        textAlign: 'center',
        maxHeight: 400,
        background: 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AuthModal onClose={() => setAuthModalOpen(false)} isOpen={authModalOpen} />
      <Grid item xs={3}>
      {useMediaQuery(theme.breakpoints.down('sm')) && location.pathname == "/all-items" &&
        <Tooltip title="Additional Filters">
         <IconButton
              onClick={()=>{dispatch(setAdditionalFilterOpen(true))}}
              style={{ color: '#2A6280', height: '100%' }}
        >
          <FilterListIcon fontSize="large"/>
        </IconButton>
        </Tooltip>}
      </Grid>
      <Grid onClick={() => history.push('/')} item xs={6} style={{ cursor: 'pointer' }}>
        <img src={logoStacked} alt="main" style={{ width: '40%', margin: 25 }} />
        <Typography sx={{fontSize:'20px', lineHeight: '20px', fontStyle:'italic'}}>Your World Travel Wardrobe</Typography>
      </Grid>
      <Grid item xs={3}>
        <Grid container style={{ justifyContent: 'space-around' }}>

          {(user === undefined || user === null) && (
            <Button
              style={{ color: '#2A6280', height: '100%' }}
              aria-label="auth"
              onClick={() => setAuthModalOpen(true)}
            >
              Sign In
            </Button>
          )}
          
          {(user !== undefined && user !== null) && (
            <AccountDropdown />
          )}
          {cart.length > 0 && (
            

            <Fab
              size="large"
              style={{borderRadius:0, position: 'fixed', bottom: 150, right: 50, padding:25, paddingTop:25}}
              color="primary"
              variant="extended"
              aria-label="cart"
              onClick={openCheckoutDrawer}
            >
                <ShoppingCartCheckoutOutlined /> 
              
              
              <Badge color="yellow" badgeContent={cart.length} style={{ paddingRight:5, marginLeft: 5}}>Checkout</Badge>

            </Fab>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
