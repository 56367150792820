import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'


/* STORES DATA FORMAT
stores: {
  byId: {
    1: {
      name: 'test store 1',
      address: 'test address 1',
      delivery_range: 6.6,
      order_count: 0,
    },
  },
  allIds: [1],
},
 */

/**
 * [storesSlice description]
 * @type {[type]}
 */
export const tripSlice = createSlice({
  name: 'trip',
  initialState: {
    location: null,
    checkIn: moment().format(),
    checkOut: moment().format(),
    members: [],
    cart: []
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload
    },
    setCheckIn: (state, action) => {
      state.checkIn = action.payload
    },
    setCheckOut: (state, action) => {
      state.checkOut = action.payload
    },
    addMember: (state, action) => {
      state.members = [...state.members, action.payload]
    },
    subtractMember: (state, action) => {
      state.members = [...state.members.slice(0, action.payload), ...state.members.slice(action.payload + 1)]
    },
    setMemberName: (state, action) => {
      const updatedMember = state.members[action.payload.index]
      updatedMember.name = action.payload.name
      state.members = [
        ...state.members.slice(0, action.payload.index),
        updatedMember,
        ...state.members.slice(action.payload.index + 1),
      ]
    },
    setMemberEmail: (state, action) => {
      const updatedMember = state.members[action.payload.index]
      updatedMember.email = action.payload.email
      state.members = [
        ...state.members.slice(0, action.payload.index),
        updatedMember,
        ...state.members.slice(action.payload.index + 1),
      ]
    },
    addProductToMemberCart: (state, action) => {
      if (state.members.length > 0) {
        const updatedMember = state.members[action.payload.index]
        updatedMember.cart = [...updatedMember.cart, action.payload.product]
        state.members = [
          ...state.members.slice(0, action.payload.index),
          updatedMember,
          ...state.members.slice(action.payload.index + 1),
        ]
      }
      state.cart = [...state.cart, action.payload.product]
    },
    removeProductFromCart: (state, action) => {
      const result = state.cart.filter(item => item.id !== action.payload);
      state.cart = result;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setLocation,
  setCheckIn,
  setCheckOut,
  addMember,
  subtractMember,
  setMemberName,
  setMemberEmail,
  addProductToMemberCart,
  removeProductFromCart
} = tripSlice.actions

export default tripSlice.reducer
