import React, {useEffect} from 'react'
import { Grid, Typography, Button, Fab } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { clearTrip } from '../redux/mainSlice'
import homeImage from '../tripWardrobeHome.jpeg'

function Home() {
  const trips = useSelector((state) => state.main.trips)
  let history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(clearTrip())
  }, [])


  return (
    <Grid container justify="center" style={{ width: '100%', minHeight: '82vh', backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${homeImage})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize:'cover'}}>
      <Grid item xs={12} style={{ padding: 15, justifyContent: 'center', alignItems: 'center'}}>
        <Grid container style={{ padding: 15, justifyContent: 'center', alignItems: 'center', textAlign:'center'}} justify="center">
          <Typography style={{fontFamily:'Noto Serif Display', color:'white'}} variant="h2"> Pick out your travel wardrobe.</Typography>
        </Grid>
        <Grid container style={{ padding: 15, justifyContent: 'center', alignItems: 'center'}} justify="center">
          <Typography variant="h2" style={{fontFamily:'Noto Serif Display', color:'white'}}> We'll do the rest.</Typography>
        </Grid>
        <Grid container style={{ padding: 15, justifyContent: 'center', alignItems: 'center'}} justify="center">
          <Button onClick={() => history.push('/all-items')} variant="contained" color="secondary" style={{borderRadius: 0, padding: 15, margin: 15, paddingLeft: 50, paddingRight: 50}}> Explore</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Home
