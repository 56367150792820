import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';


export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmingLogout, setConfirmingLogout] = React.useState(false);
  const open = Boolean(anchorEl);
  const signOutUser = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setConfirmingLogout(false);
  };
  const handleConfirmLogout = () => {
    if(!confirmingLogout){
        setConfirmingLogout(true)
    }
    else {
        signOutUser()
        handleClose()
    }
  }

  /*
  <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
  */
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
            <IconButton
              style={{ color: '#2A6280', height: '100%' }}
              onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
              <AccountCircleIcon fontSize="large"/>
            </IconButton>
          
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius:0,
            pb:0,
            mb:0,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem style={{color:"#DB6028", justifyContent:'center' }} disableElevation onClick={handleConfirmLogout}>
          {!confirmingLogout && (
            <Button  color="redorange" style={{ borderRadius:0, textDecoration:'none', paddingLeft: 4}} size='small'>
                <Logout style={{color:"#DB6028", marginRight: 12, marginLeft:0, paddingLeft:0 }} fontSize="small" />
                Logout
            </Button>
          )}
          {confirmingLogout && (
            <Button disableElevation variant='contained' color="redorange" style={{ color:"black", borderRadius:0 }} size='small'>
                <Logout style={{ marginRight: 12, marginLeft:0, paddingLeft:0 }} fontSize="small" />
                Confirm Logout
            </Button>
          )}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
