import React, { useState } from "react"
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Paper,
  InputBase,
  TextField,
  Popover,
  Stack,
  ButtonGroup,
  Alert,
  Snackbar,
} from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
//import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import SearchIcon from "@mui/icons-material/SearchOutlined"

// NOTE: INCONSISTENT SEMICOLON USE
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import RemoveIcon from "@mui/icons-material/RemoveOutlined"
import AddIcon from "@mui/icons-material/AddOutlined"
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined"
// REDUX
import { setLocation, setCheckIn, setCheckOut, addMember, subtractMember } from '../redux/tripSlice'
// Map
import MapBox from './destination/MapBox';
import PopMap from './destination/PopMap';

// TODO: DESIGN NOT RESPONSIVE, SPECIFICALLY THE MAIN BAR CHECKOUT DATE SELECTOR
export default function TripBar() {
  let history = useHistory()
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.main.cart)
  const [checkIn, setCheckInVal] = useState(Date.now())
  const [checkOut, setCheckOutVal] = useState(Date.now())
  const [adults, setAdults] = useState(0)
  const [children, setChildren] = useState(0)
  const [filterTouched, setFilterTouched] = useState(false)
  const [value, setValue] = useState([null, null])

  const handleChangeLocation = (e) => {
    dispatch(setLocation(e.target.value))
  }

  const handleChangeCheckIn = (newValue) => {
    setCheckInVal(newValue)
    dispatch(setCheckIn(newValue.toString()))
  }
  const handleChangeCheckOut = (newValue) => {
    setCheckOutVal(newValue)
    dispatch(setCheckOut(newValue.toString()))
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddAdults = () => {
    setAdults(adults + 1)
    dispatch(addMember({ name: `Adult ${adults+1}`, adult: true, cart: [] }))
  }

  const handleAddChildren = () => {
    setChildren(children + 1)
    dispatch(addMember({ name: `Child${children+1}`, adult: false, cart: [] }))
  }

  const handleSubtractAdults = () => {
    if (adults > 0) {
      const total = adults + children - 1
      dispatch(subtractMember(total))
      setAdults(adults - 1)
    }
  }

  const handleSubtractChildren = () => {
    if (children > 0) {
      const total = adults + children - 1
      dispatch(subtractMember(total))
      setChildren(children - 1)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        style={{ width: "100%", textAlign: "center", 
          //maxHeight: 300 
        }}
      >
        <Grid
          container
          style={{
            padding: 15,
            backgroud: 'none',
            //backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            component="form"
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: 0,
            }}
          >
            <Grid container >
              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 0,
                }}
              >
                <PopMap />
              </Grid>
              {false && (
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              )}
              {/* TODO: THIS PIKER HAS SOME ODD CLICKING FUNCTIONALITY...TESTING ON CHROME */}
              <Grid item xs={12} md={6} lg={2.5} sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 0,
                }}>
                <DatePicker
                  disablePast
                  label="Check-in"
                  value={checkIn}
                  onChange={handleChangeCheckIn}
                  renderInput={(params) => (
                    <TextField
                    fullWidth
                      //style={{ margin: 5, maxWidth: 175 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </Grid>
              {false && (
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              )}
              <Grid item xs={12} md={6} lg={2.5} sx={{
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 0,
                }}>
                <DatePicker
                  label="Check-out"
                  value={checkOut}
                  onChange={handleChangeCheckOut}
                  renderInput={(params) => (
                    <TextField
                    fullWidth
                      //style={{ margin: 5, maxWidth: 175 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </Grid>
              {false && (
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              )}
              <Grid item xs={12} lg={3}>
                <Grid container>
                <Grid item xs={6} sx={{
                  //p: 1,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 0,
                  justifyContent: "center"
                }}>
                <IconButton
                  aria-describedby={id}
                  color="primary"
                  //sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={handleClick}
                >
                  <PersonAddIcon />
                  <Typography style={{ padding: 10 }}>
                    {" "}
                    {adults + children}
                  </Typography>
                </IconButton>
                </Grid>
                <Grid item xs={6} sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 0,
                  }}>
                  <Button
                    fullWidth
                    variant="contained"
                    disableElevation
                    style={{
                      borderRadius: 0,
                      padding: 5,
                      backgroundColor: "#2A6280",
                      margin: 5,
                      color: "White",
                    }}
                    onClick={(e) => {
                      setFilterTouched(true)
                      if(adults + children >0){
                        history.push("/trip-members")
                        setFilterTouched(false)
                      }
                    }}
                  >
                    Go &rarr;
                  </Button>
                </Grid>
                </Grid>
              </Grid>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Paper elevation={3} style={{ padding: 20, margin: 20 }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Stack
                        component="form"
                        spacing={2}
                        noValidate
                        autoComplete="off"
                      >
                        <div>
                          <Typography style={{ padding: 10 }}>
                            {" "}
                            {adults}: Adults
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{ padding: 10 }}>
                            {" "}
                            {children}: Children
                          </Typography>
                        </div>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        component="form"
                        spacing={2}
                        noValidate
                        autoComplete="off"
                      >
                        <ButtonGroup
                          style={{ padding: 5 }}
                          fullWidth
                          disableElevation
                          variant="contained"
                        >
                          <Button
                            style={{ borderRadius: 0 }}
                            onClick={handleSubtractAdults}
                          >
                            <RemoveIcon />
                          </Button>
                          <Button
                            style={{ borderRadius: 0 }}
                            onClick={handleAddAdults}
                          >
                            <AddIcon />
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup
                          style={{ padding: 5 }}
                          fullWidth
                          disableElevation
                          variant="contained"
                        >
                          <Button
                            style={{ borderRadius: 0 }}
                            onClick={handleSubtractChildren}
                          >
                            <RemoveIcon />
                          </Button>
                          <Button
                            style={{ borderRadius: 0 }}
                            onClick={handleAddChildren}
                          >
                            <AddIcon />
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Popover>
              {false && <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />}
              <Grid item md={9}></Grid>
              <Grid item xs={12} md={3}>
                <Snackbar open={filterTouched && (adults + children)<1} anchorOrigin={{ vertical: 'top', horizontal: 'center', }} style={{top:210}}><Alert severity="error">Please add at least one traveler.</Alert></Snackbar>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
